import classNames from "classnames"
import { DisplaySourceTypeProps } from "."
import { getIpfsVideoStreamUrl } from "utility/ipfs"

interface DisplaySourceVideoProps extends DisplaySourceTypeProps {}

const DisplaySourceVideo = ({ src, displayClassName, displayStyle }: DisplaySourceVideoProps) => {
  const isFromPlatform = /youtu.be|youtube.com/.test(src)
  if (isFromPlatform) {
    return (
      <iframe
        width={displayStyle?.width || "100%"}
        height={displayStyle?.height || "100%"}
        src={src}
        title="video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        className={classNames("display-source-video", { [displayClassName]: displayClassName })}
      ></iframe>
    )
  }
  return (
    <video
      muted
      playsInline
      loop
      autoPlay
      className={classNames("display-source-video", { [displayClassName]: displayClassName })}
      style={displayStyle}
      src={getIpfsVideoStreamUrl(src)}
    />
  )
}

export default DisplaySourceVideo
