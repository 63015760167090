import { axiosBase } from "./../services/axiosbase"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { WhiteLabel } from "../interfaces/whitelabel"
export const defaultWhiteLabel = {} as WhiteLabel

export const fetchWhiteLabel = createAsyncThunk(
  "whitelabel",
  async (params: {
    communityId?: string
    companyId?: string
    defaultMarketPlaceData?: any
    isCustomDomain?: boolean
    customDomainCompanyId?: string
  }) => {
    try {
      const { communityId, companyId, defaultMarketPlaceData, isCustomDomain, customDomainCompanyId } = params
      const cookiesCommunityId = localStorage.getItem("communityId")
      const cookiesCompanyId = localStorage.getItem("companyId")

      if (!(communityId || cookiesCommunityId || cookiesCompanyId || companyId)) {
        const { data } = await axiosBase.get(`/white-label/templates/default`)
        if (data) {
          return data
        }
        return defaultWhiteLabel
      }

      if (communityId || cookiesCommunityId) {
        const id = communityId || cookiesCommunityId

        if (isCustomDomain && customDomainCompanyId && defaultMarketPlaceData?.type === "company") {
          const { data } = await axiosBase.get(`/white-label/company/${customDomainCompanyId}`)
          return data
        }

        const { data } = await axiosBase.get(`/white-label/community/${id}`)
        if (data?.companyId) {
          const { data: companyWhiteLabel } = await axiosBase.get(`/white-label/company/${data?.companyId}`)
          data.auth = companyWhiteLabel?.auth
        }
        return data
      }

      if (companyId || cookiesCompanyId) {
        let id = companyId || cookiesCompanyId

        if (isCustomDomain && defaultMarketPlaceData.type === "company" && customDomainCompanyId) {
          id = customDomainCompanyId
        }

        const { data } = await axiosBase.get(`/white-label/company/${id}`)
        return data
      }

      throw new Error("Invalid parameters")
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data)
      }
      throw new Error("An error occurred")
    }
  }
)

export const whitelabelConfigSlice = createSlice({
  name: "whitelabelConfig",
  initialState: {
    whitelabel: defaultWhiteLabel,
    loading: true,
    errorMessage: undefined
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhiteLabel.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchWhiteLabel.fulfilled, (state, { payload }) => {
        state.loading = false
        state.whitelabel = payload
      })
      .addCase(fetchWhiteLabel.rejected, (state, { payload }) => {
        state.loading = false
        state.errorMessage = payload
      })
  }
})

export type WhiteLabelConfigState = ReturnType<typeof whitelabelConfigSlice.reducer>
export default whitelabelConfigSlice.reducer
