import { useCustomDomainContext } from "contexts/CustomDomainContext"
import { cookies } from "./cookies"
import seedrandom from "seedrandom"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => (!!cookies.get("userData") ? JSON.parse(cookies.get("userData")) : null)
export const getUserData = () => (!!cookies.get("userData") ? JSON.parse(cookies.get("userData")) : null)

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  const { defaultRoute } = useCustomDomainContext()

  if (userRole === "admin") return defaultRoute
  if (userRole === "client") return "/access-control"
  return "/weispace"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

const createHex = (seed = "") => {
  let hexCode = ""
  const hexValues = "0123456789abcdef"
  const seededRandomGenerator = seedrandom(seed)

  for (let i = 0; i < 6; i++) {
    let hexChar = hexValues.charAt(Math.floor(Math.random() * hexValues.length))
    // Esse seed !== "1" é para ele não achar que o seed '1' é um input proposital
    if (seed && seed !== "1") {
      hexChar = hexValues.charAt(Math.floor(seededRandomGenerator() * hexValues.length))
    }
    hexCode += hexChar
  }
  return hexCode
}

export const generateGradientColor = (seed = "") => {
  let deg = Math.floor(Math.random() * 360)

  if (seed) {
    const seededRandomGenerator = seedrandom(seed)
    deg = Math.floor(seededRandomGenerator() * 360)
  }
  // const gradient = `linear-gradient(${deg}deg, #${createHex()}, #${createHex()})`
  const gradient = `linear-gradient(${deg}deg, #${createHex(seed)}, #${createHex(`${seed}1`)})`
  return gradient
}

/**
 * Returns the scan url according to the contract network
 * @param {Enum("polygon_mainnet" | "polygon_amoy" | "polygon_mumbai" | "ethereum_mainnet" | "ethereum_goerli" | "celo_alfajores" | "gnosis_mainnet" | "gnosis_testnet" | "chiliz_mainnet" | "chiliz_testnet" | "base_mainnet" | "base_sepolia")} address string
 * @param {Enum("address" | "tx")}  type string
 *
 * @returns {String} blockchain scan url address string
 */
export const getNetworkUrl = (network, type = "address") => {
  const networkUrls = {
    polygon_mainnet: {
      address: "https://polygonscan.com/address",
      tx: "https://polygonscan.com/tx"
    },
    polygon_amoy: {
      address: "https://www.oklink.com/amoy/address",
      tx: "https://www.oklink.com/amoy/tx"
    },
    polygon_mumbai: {
      address: "https://mumbai.polygonscan.com/address",
      tx: "https://mumbai.polygonscan.com/tx"
    },
    ethereum_mainnet: {
      address: "https://etherscan.io/address",
      tx: "https://etherscan.io/tx"
    },
    ethereum_goerli: {
      address: "https://goerli.etherscan.io/address",
      tx: "https://goerli.etherscan.io/tx"
    },
    celo_alfajores: {
      address: "https://alfajores.celoscan.io/address",
      tx: "https://alfajores.celoscan.io/tx"
    },
    gnosis_mainnet: {
      address: "https://gnosisscan.io/address",
      tx: "https://gnosisscan.io/tx"
    },
    gnosis_testnet: {
      address: "https://gnosis-chiado.blockscout.com/address",
      tx: "https://gnosis-chiado.blockscout.com/tx"
    },
    chiliz_mainnet: {
      address: "https://chiliscan.com/address",
      tx: "https://chiliscan.com/tx"
    },
    chiliz_testnet: {
      address: "https://testnet.chiliscan.com/address",
      tx: "https://testnet.chiliscan.com/tx"
    },
    base_mainnet: {
      address: "https://basescan.org/address",
      tx: "https://basescan.org/tx"
    },
    base_sepolia: {
      address: "https://sepolia-explorer.base.org/address",
      tx: "https://sepolia-explorer.base.org/tx"
    }
  }

  const url = networkUrls[network]
  if (url) {
    return url[type]
  }
}

const blacklistedDomains = ["bluebell", "tokenprivate"]

export const canLabel = () => {
  if (blacklistedDomains.some((domain) => window.location.hostname.includes(domain))) {
    return false
  }
  return true
}

export function middleElipsis(text, maxCharCount, prefixCharCount = 5, sufixCharCount = 5, disableElipsis = false) {
  if (!text) return text
  if (disableElipsis) return text
  if (text?.length <= maxCharCount) return text
  return `${text?.substr(0, prefixCharCount)}...${text.substr(text?.length - sufixCharCount)}`
}

export function faviconToShow(originalFavicon) {
  if (window.location.hostname.includes("bluebell")) {
    return "https://gotokens.s3.amazonaws.com/production/company_b82f617f-1d01-40c7-aa66-48dc54035f89/assets/web/icon.jpeg"
  }
  if (blacklistedDomains.some((domain) => window.location.hostname.includes(domain))) {
    return "none"
  }
  return originalFavicon
}

export function formatNumber(number, language = "pt") {
  const numberFormatLanguage = {
    en: "en-US",
    pt: "pt-BR"
  }

  return new Intl.NumberFormat(numberFormatLanguage[language]).format(number)
}

function truncateNumber(number, decimals) {
  const numberString = number.toString()
  const decimalIndex = numberString.indexOf(".")

  if (decimalIndex === -1) {
    return numberString
  }

  return numberString.substring(0, decimalIndex + decimals + 1)
}

export function formatNumberWithFactor(number, factor = 0, truncateFactor = 2) {
  if (number) {
    if (factor === 0) {
      return number?.toString() // Retorna o número original como string
    }

    const divisor = Math.pow(10, factor)
    const numeroFormatado = truncateNumber(Number(number) / divisor, truncateFactor) // Sempre com 2 casas decimais
    return numeroFormatado
  }
  return ""
}
