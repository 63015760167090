import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { validateUserCode, validateUserEmailAndSendCode } from "services/authRequests"
import { loginByEmailCode } from "services/store"
import { AuthModalsEnum, useAuthContext } from "../AuthContext"
import useEmailAuthForm, { EmailAuthData, EmailAuthStepStages } from "./email/useEmailForm"
import useLogger from "utility/hooks/logger/useLogger"

type SubmitHandlerCallbacks = {
  [key in keyof typeof EmailAuthStepStages]: () => Promise<void>
}

const useEmailAuth = ({
  setIsAuthModalOpen,
  setIsUserLoggedIn,
  networkMap,
  setCurrentAuthStageShowing,
  currentAuthStageShowing
}) => {
  const dispatch = useAppDispatch()
  const { isFirebaseAuthLoading, isMetamaskAuthLoading } = useAuthContext()
  const { companyWhitelabel, whitelabel } = useAppSelector((state) => state.whitelabelConfig)

  const [emailAuthStep, setEmailAuthStep] = useState<number>(1) // 0: initial, 1: email input, 2: email sent, 3: email verified
  const { JWTLoading, JWT, authIsExclusive, userAccessPermissionIsCompleted, shouldUserDoKyc } = useAppSelector(
    (state) => state.auth
  )
  const { createLogger } = useLogger(true)

  const submitHandler = async (formData: EmailAuthData) => {
    const callbacks: SubmitHandlerCallbacks = {
      [EmailAuthStepStages.email_form]: async () => {
        await processUserEmail()
      },
      [EmailAuthStepStages.otp_verification]: async () => {
        await handleEmailLogin()
      },
      [EmailAuthStepStages.verified]: async () => {}
    }

    const callbackMethod = callbacks[formData?.stepStage]
    if (callbackMethod) {
      await callbackMethod()
    }
  }

  const { extraInputChangeHandler, isEmailAuthLoading, resetFields, manageCallbackErrors, ...emailForm } =
    useEmailAuthForm({ submitHandler })
  const { handleInputChange, formData } = emailForm
  const { email, otpCode } = formData

  useEffect(() => {
    resetFields()
  }, [isFirebaseAuthLoading, isMetamaskAuthLoading])

  const navigateEmailStep = () => {
    resetFields()
    setEmailAuthStep(1)
  }

  const navigateEmailCodeStep = () => {
    setCurrentAuthStageShowing(AuthModalsEnum.OTP_VERIFICATION)
  }

  const resetEmailStep = () => {
    resetFields()
    setEmailAuthStep(1)
    setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
  }

  const changeHandleSubmitStep = ({ stepValue }: { stepValue: EmailAuthStepStages }) => {
    emailForm?.handleInputChange({ target: { name: "stepStage", value: stepValue } })
  }

  const processUserEmail = async () => {
    let company = companyWhitelabel?.companyId || whitelabel?.companyId

    const isCustomDomain = localStorage.getItem("isCustomDomain")
    const localStorageCompanyId = localStorage.getItem("companyId")
    if (isCustomDomain === "true" && localStorageCompanyId) {
      company = localStorageCompanyId
    }
    if (!company) {
      company = ""
    }
    const network = process.env.REACT_APP_ENVIRONMENT === "production" ? networkMap["137"] : networkMap["80002"]
    try {
      await validateUserEmailAndSendCode({ email }, network, company)
      navigateEmailCodeStep()
      changeHandleSubmitStep({ stepValue: EmailAuthStepStages.otp_verification })
    } catch (err) {
      console.log(err)
      manageCallbackErrors({ name: "email", value: "Email_Not_Found" })
    }
  }

  const handleLoginByEmailCode = async (result) => {
    dispatch(
      loginByEmailCode({
        ...result?.data
      })
    )
  }

  const handleEmailLogin = async () => {
    try {
      emailForm.setIsSubmit(true)
      const result = await validateUserCode({ email, code: otpCode })
      handleLoginByEmailCode(result)

      setIsUserLoggedIn(true)
      emailForm.setIsSubmit(false)
    } catch (err) {
      const mappedStatusCodeErrors = {
        410: () => {
          handleInputChange({ target: { name: "otpCode", value: "" } })

          return { message: "Email_OTP_Expired_Code" }
        },
        429: () => {
          resetEmailStep()

          return { message: "Email_OTP_Too_Many_Attempts" }
        },
        401: () => {
          return { message: "Email_OTP_Invalid_Code" }
        },
        400: () => {
          resetEmailStep()

          return { message: "Email_OTP_Invalid_Email" }
        }
      }

      const errorStatusCode = err?.response?.status
      const errorCallback = mappedStatusCodeErrors[errorStatusCode]

      let messageError = "Email_OTP_Generic_Error"

      if (errorCallback) {
        const { message } = errorCallback()
        messageError = message
      }

      emailForm?.setErrors({ otpCode: messageError } as EmailAuthData)
    }
  }

  const handleMakeLogin = () => {
    resetEmailStep()
    setIsAuthModalOpen(false)
    setIsUserLoggedIn(true)
  }

  // useEffect(() => {
  //   const logger = createLogger("UseEmailAuth")
  //   if (!JWTLoading && JWT && currentAuthStageShowing === AuthModalsEnum.OTP_VERIFICATION) {
  //     logger.add("- Usuario logando")
  //     handleMakeLogin()
  //     // if (authIsExclusive) {
  //     //   logger.add("- Usuário precisa ser exclusivo")
  //     //   if (userAccessPermissionIsCompleted) {
  //     //     logger.add("- Usuário foi convidado")
  //     //     logger.add("- Realizando Login")
  //     //     handleMakeLogin()
  //     //   } else {
  //     //     logger.add("- Usuário não foi convidado")
  //     //     if (shouldUserDoKyc) {
  //     //       logger.add("- Usuário precisa fazer KYC")
  //     //       logger.add("- Realizando Login")
  //     //       logger.add("- Acesso do usuário foi negado")
  //     //       handleMakeLogin()
  //     //       setCurrentAuthStageShowing(AuthModalsEnum.ACCESS_DENIED)
  //     //     } else {
  //     //       logger.add("- Usuário não precisa fazer KYC")
  //     //       logger.add("- Realizando Login")
  //     //       logger.add("- Acesso do usuário foi aceito")
  //     //       handleMakeLogin()
  //     //     }
  //     //   }
  //     //   logger.read()
  //     // } else {
  //     //   logger.add("- Usuário não precisa ser exclusivo")
  //     //   logger.add("- Realizando Login")
  //     //   handleMakeLogin()
  //     //   logger.read()
  //     // }
  //   }
  // }, [JWTLoading, JWT, authIsExclusive, userAccessPermissionIsCompleted])

  return {
    handleEmailLogin,
    navigateEmailCodeStep,
    navigateEmailStep,
    processUserEmail,
    isEmailAuthLoading,
    emailAuthStep,
    resetEmailStep,
    emailForm
  }
}

export default useEmailAuth
